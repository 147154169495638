<template>
    <div class="mb-5 mb-xl-8 card">
        <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3 mb-1">{{ $t("pages.roadmapstatistics.title") }}</span>
            </h3>
            <div class="align-items-end flex-column">
                <a class="btn btn-success btn-light" data-bs-target="#kt_modal_1" data-bs-toggle="modal" @click.prevent="downloadUsers()">{{ $t("pages.roadmapstatistics.downloadRoadmaps") }}</a>
            </div>
        </div>
    </div>
    <div v-if="!loading" class="row">
        <div class="col-6">
            <div class="card card-xl-stretch mb-xl-8">
                <!--begin::Beader-->
                <div class="card-header border-0 py-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder fs-3 mb-1">{{ $t("pages.roadmapstatistics.creationRate") }}</span>
                        <span class="text-muted fw-bold fs-7">{{ $t("pages.roadmapstatistics.creationDesc") }}</span>
                    </h3>
                </div>
                <!--end::Header-->

                <!--begin::Body-->
                <div class="card-body d-flex flex-column">
                    <div class="flex-grow-1">
                        <!--begin::Chart-->
                        <apexchart :options="chartOptions" :series="series" class="mixed-widget-4-chart" height="250" type="radialBar"></apexchart>
                        <!--end::Chart-->
                    </div>

                    <div class="pt-5">
                        <p class="text-center fs-6 pb-5">
                          <span class="badge badge-light-danger fs-8">Notes:</span>&nbsp; {{ $t("pages.userstatistics.connectionRateDisclaimer") }} <br />{{ this.totalUsers }} {{ $t("pages.userstatistics.connectionRateDisclaimerSecond") }}
                        </p>
                    </div>
                </div>
                <!--end::Body-->
            </div>
        </div>
        <div class="col-6">
            <div class="card card-xl-stretch mb-xl-8">
                <!--begin::Beader-->
                <div class="card-header border-0 py-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder fs-3 mb-1">{{ $t("pages.roadmapstatistics.categoryDistribution") }}</span>
                        <span class="text-muted fw-bold fs-7">{{ $t("pages.roadmapstatistics.categoryDistributionDesc") }}</span>
                    </h3>
                </div>
                <!--end::Header-->

                <!--begin::Body-->
                <div class="card-body d-flex flex-column">
                    <div class="flex-grow-1">
                        <!--begin::Chart-->
                        <apexchart :options="chartPieOptions" :series="seriesPie" class="mixed-widget-4-chart" height="225" type="donut"></apexchart>
                        <!--end::Chart-->
                    </div>

                    <div class="pt-5">
                        <p class="text-center fs-6 pb-5">
                          <span class="badge badge-light-danger fs-8">Notes:</span>&nbsp; {{ $t("pages.userstatistics.connectionRateDisclaimer") }} <br />{{ this.totalUsers }} {{ $t("pages.userstatistics.connectionRateDisclaimerSecond") }}
                        </p>
                    </div>
                </div>
                <!--end::Body-->
            </div>
        </div>
        <div class="col-6">
            <div class="card card-xl-stretch mb-xl-8">
                <!--begin::Beader-->
                <div class="card-header border-0 py-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder fs-3 mb-1">{{ $t("pages.roadmapstatistics.roadmapStatus") }}</span>
                        <span class="text-muted fw-bold fs-7">{{ $t("pages.roadmapstatistics.roadmapStatusDesc") }}</span>
                    </h3>
                </div>
                <!--end::Header-->

                <!--begin::Body-->
                <div class="card-body d-flex flex-column">
                    <div class="flex-grow-1">
                        <!--begin::Chart-->
                        <apexchart :options="chartBarOptions" :series="seriesBar" class="mixed-widget-4-chart" height="225" type="bar"></apexchart>
                        <!--end::Chart-->
                    </div>

                    <div class="pt-5">
                        <p class="text-center fs-6 pb-5">
                          <span class="badge badge-light-danger fs-8">Notes:</span>&nbsp; {{ $t("pages.userstatistics.connectionRateDisclaimer") }} <br />{{ this.totalUsers }} {{ $t("pages.userstatistics.connectionRateDisclaimerSecond") }}
                        </p>
                    </div>
                </div>
                <!--end::Body-->
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import roadMapApi from "@/core/services/RoadMap";
import XLSX from "xlsx";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";
import userApi from "@/core/services/User";
import xlsx from "json-as-xlsx";
import moment from "moment";

function getLevelCompany(company, level) {
    if (company.position === level) {
        return company.name;
    } else if (company.father) {
        return getLevelCompany(company.father, level);
    } else return "";
}

export default defineComponent({
    name: "related-roadmap",
    components: {},
    data: function () {
        return {
            filters: {
                perimeter: "",
                identity: "",
            },
            filterUsers: {
                identity: "",
                email: "",
                user_type: "",
                status: "",
            },
            action: {
                id: 0,
                label: "",
                collaborators: "",
                deadline: "",
                category: 0,
                status: "TODO",
                commentary: "",
                impact: "",
            },
            chartBarOptions: {
                chart: {
                    fontFamily: "inherit",
                    type: "bar",
                },
                plotOptions: {
                    bar: {
                        distributed: true,
                    },
                },
                labels: [] as any,
            },
            seriesBar: [] as any,
            chartPieOptions: {
                chart: {
                    fontFamily: "inherit",
                    type: "donut",
                },
                plotOptions: {
                    pie: {
                        customScale: 1,
                    },
                },
                labels: [] as any,
            },
            seriesPie: [] as any,
            chartOptions: {
                chart: {
                    fontFamily: "inherit",
                    type: "radialBar",
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "65%",
                        },
                        dataLabels: {
                            showOn: "always",
                            name: {
                                show: false,
                                fontWeight: "700",
                            },
                            value: {
                                color: "#009EF7",
                                fontSize: "30px",
                                fontWeight: "700",
                                offsetY: 12,
                                show: true,
                                formatter: function (val) {
                                    return val + "%";
                                },
                            },
                        },
                        track: {
                            background: "#F5F8FA",
                            strokeWidth: "100%",
                        },
                    },
                },
                colors: ["#009EF7"],
                stroke: {
                    lineCap: "round",
                },
                labels: [],
            },
            totalUsers: 0,
            roadMapUser: 0,
            series: [] as any,
            users: [],
            roadmaps: [],
            categories: [],
            loading: true,
            noResult: false,
        };
    },
    methods: {
        downloadUsers() {
            let data = [
                {
                    sheet: "Extraction Roadmaps",
                    columns: [
                        {
                            label: "Nom",
                            value: (row) => row.label,
                        },
                        {
                            label: "Catégorie",
                            value: (row) => {
                                if (row.category) {
                                    return row.category.label;
                                } else {
                                    return "";
                                }
                            },
                        },

                        {
                            label: "Status",
                            value: (row) => {
                                if (row.status === "INPR") {
                                    return "In Progress";
                                } else if (row.status === "TODO") {
                                    return "To do";
                                } else if (row.status === "DONE") {
                                    return "Done";
                                }
                            },
                        },
                        {
                            label: "Deadline",
                            value: (row) => {
                                if (row.deadline) {
                                    return row.deadline;
                                } else {
                                    return "";
                                }
                            },
                        },
                        {
                            label: "Impact",
                            value: (row) => {
                                return row.impact;
                            },
                        },
                        {
                            label: "Date de création",
                            value: (row) => {
                                return moment(row.date_created).format("DD/MM/YYYY");
                            },
                        },
                        {
                            label: "Date de mise à jour",
                            value: (row) => {
                                return moment(row.updated).format("DD/MM/YYYY");
                            },
                        },
                        {
                            label: "Email",
                            value: (row) => {
                                if (row.user) {
                                    return row.user.email;
                                }
                                return "";
                            },
                        },
                        {
                            label: "N1",
                            value: (row) => {
                                if (row.user && row.user.userprofile) {
                                    return getLevelCompany(row.user.userprofile.related_companies[0], 1);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N2",
                            value: (row) => {
                                if (row.user && row.user.userprofile) {
                                    return getLevelCompany(row.user.userprofile.related_companies[0], 2);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N3",
                            value: (row) => {
                                if (row.user && row.user.userprofile) {
                                    return getLevelCompany(row.user.userprofile.related_companies[0], 3);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N4",
                            value: (row) => {
                                if (row.user && row.user.userprofile) {
                                    return getLevelCompany(row.user.userprofile.related_companies[0], 4);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N5",
                            value: (row) => {
                                if (row.user && row.user.userprofile) {
                                    return getLevelCompany(row.user.userprofile.related_companies[0], 5);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N6",
                            value: (row) => {
                                if (row.user && row.user.userprofile) {
                                    return getLevelCompany(row.user.userprofile.related_companies[0], 6);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N7",
                            value: (row) => {
                                if (row.user && row.user.userprofile) {
                                  return getLevelCompany(row.user.userprofile.related_companies[0], 7);
                                } return "";
                            },
                        },
                        {
                            label: "N8",
                            value: (row) => {
                                if (row.user && row.user.userprofile) {
                                    return getLevelCompany(row.user.userprofile.related_companies[0], 8);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N9",
                            value: (row) => {
                                if (row.user && row.user.userprofile) {
                                    return getLevelCompany(row.user.userprofile.related_companies[0], 9);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N10",
                            value: (row) => {
                                if (row.user && row.user.userprofile) {
                                    return getLevelCompany(row.user.userprofile.related_companies[0], 10);
                                }
                                return "";
                            },
                        },
                    ],
                    content: this.roadmaps,
                },
            ];
            let settings = { fileName: "extraction_roadmaps" };

            xlsx(data, settings);
        },
        fetchUsers() {
            this.loading = true;
            userApi.getUsers(this.filterUsers).then((response) => {
                this.users = response.data["users"];
                this.roadMapUser = response.data["user_with_roadmaps"];
                this.totalUsers = this.users.length;
                console.log(this.roadMapUser);
                this.series = [Math.round((this.roadMapUser * 100) / this.totalUsers)];
                this.loading = false;
            });
        },
        getRelatedRoadMaps() {
            this.loading = true;
            this.noResult = false;
            roadMapApi.getAllRoadMaps(this.filters).then((response) => {
                let roadmaps = response.data.roadmaps;
                this.roadmaps = response.data.roadmaps;
                let categories = response.data.categories;
                categories.forEach((category) => {
                    this.chartPieOptions.labels.push(category.label);
                    this.seriesPie.push(category.roadmaps.length);
                });
                let inprogress_roadmaps = roadmaps.filter((roadmap) => {
                    if (roadmap.status === "INPR") return roadmap;
                }).length;
                let todo_roadmaps = roadmaps.filter((roadmap) => {
                    if (roadmap.status === "TODO") return roadmap;
                }).length;
                let done_roadmaps = roadmaps.filter((roadmap) => {
                    if (roadmap.status === "DONE") return roadmap;
                }).length;
                this.chartBarOptions.labels = ["A FAIRE", "EN COURS", "TERMINÉE"];
                this.seriesBar = [
                    {
                        data: [
                            {
                                x: "A FAIRE",
                                y: todo_roadmaps,
                            },
                            {
                                x: "EN COURS",
                                y: inprogress_roadmaps,
                            },
                            {
                                x: "TERMINÉE",
                                y: done_roadmaps,
                            },
                        ],
                    },
                ];
                this.loading = false;
            });
        },
    },
    setup() {
        const { t } = useI18n();

        function createDebounce() {
            let timeout: ReturnType<typeof setTimeout>;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        return { t, debounce: createDebounce() };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.roadmap.title"), [
                {
                    link: "/roadmap/",
                    label: this.t("pages.roadmap.title"),
                },
            ]);
        },
    },
    created() {
        this.getRelatedRoadMaps();
        this.fetchUsers();
    },
});
</script>
